import React from "react";
import classes from "./Profile.module.css";
// import PropTypes from 'prop-types'

function Profile() {
  return <div className={classes.Profile}> Profile</div>;
}

// Profile.propTypes = {}

export default Profile;
